<template>
  <div class="wrapper div-padding" id="usecase">
    <div class="home-sec-4-box box">
      <div id="tab-wrapper">
        <div id="sec-4-title">
          <h3>{{ $t("website.homepage.usecase.title") }}</h3>
        </div>
        <div id="tabs-control">
          <ul id="tabs">
            <li
              class="tab"
              :class="{ selected: tPaySelected }"
              @click.stop.prevent="tPayOnClick()"
            >
              <h6 class="pt-4">
                {{ $t("website.homepage.usecase.tverifytitle") }}
              </h6>
            </li>
            <li
              class="tab"
              :class="{ selected: tVerifySelected }"
              @click.stop.prevent="tVerifyOnClick()"
            >
              <h6 class="pt-4">
                {{ $t("website.homepage.usecase.tpaytitle") }}
              </h6>
            </li>
            <li class="tab">
              <span style="color: #8a8a8a">(Coming soon)</span>
              <h6 class="pt-1">Formidable forms</h6>
            </li>
          </ul>
        </div>
        <div class="tab-texts">
          <div class="tab-text" :class="{ active: tPaySelected }">
            <h6>{{ $t("website.homepage.usecase.tverifysubtitle") }}</h6>
            <p>
              {{ $t("website.homepage.usecase.tverifydescription") }}
            </p>
            <b-button
              pill
              class="btn-tekkis btn-hover text-700-22 btn btn-secondary btn-sm rounded-pills mt-4"
              style="font-size: 18px; width: auto; padding: 10px 25px 10px 25px"
              to="/solutions-tverify"
              v-scroll-to="'#solutions-pg'"
              >{{ $t("website.homepage.usecase.tverifybtn") }}
              <img
                class="img-fit-div"
                style="width: 15px"
                src="@/assets/img/arrow-right-white.png"
                alt=""
            /></b-button>
          </div>
          <div class="tab-text" :class="{ active: tVerifySelected }">
            <h6>{{ $t("website.homepage.usecase.tpaysubtitle") }}</h6>
            <p>
              {{ $t("website.homepage.usecase.tpaydescription") }}
            </p>
            <b-button
              pill
              class="btn-tekkis btn-hover text-700-22 btn btn-secondary btn-sm rounded-pills mt-4"
              style="font-size: 18px; width: auto; padding: 10px 25px 10px 25px"
              to="/solutions-tpay"
              v-scroll-to="'#solutions-tpay-pg'"
              >{{ $t("website.homepage.usecase.tpaybtn") }}
              <img
                class="img-fit-div"
                style="width: 15px"
                src="@/assets/img/arrow-right-white.png"
                alt=""
            /></b-button>
          </div>
        </div>
      </div>
      <div class="tab-images">
        <div class="tab-image" :class="{ active: tPaySelected }">
          <img src="@/assets/img/girl-identification.webp" alt="" />
          <img src="@/assets/img/verify-documents.webp" alt="" />
        </div>
        <div class="tab-image" :class="{ active: tVerifySelected }">
          <img src="@/assets/img/invoice.webp" alt="" />
          <img src="@/assets/img/payment_link.webp" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tab-text,
.tab-image {
  display: none;
}
.active {
  display: block;
}
.home-sec-4-box {
  padding: 3rem 0 2.5rem 0;
}
#sec-4-title {
  text-align: center;
}
#tabs {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  justify-content: center;
  margin: 1.75rem 0;

  .selected {
    border-bottom: 6px solid #2d23a8;
  }
  .selected h3 {
    color: #5c52ca;
  }
}
ul .tab {
  list-style-type: none;
  text-align: center;
  padding: 0 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  h3 {
    font-size: 1.3rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
  }
  @media screen and (max-width: 768px) {
    h6 {
      font-size: 0.9rem !important;
    }
    span {
      font-size: 0.5rem !important;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 33.33%;
  }
}

.tab-text h4 {
  font-size: 1.25rem;
  margin: 2.25rem 0 1.5rem 0;
}

.tab-images {
  margin-top: 2rem;
  img {
    width: fit-content;
    height: fit-content;
    @media only screen and (max-width: 576px) {
      width: 100%;
      height: auto;
    }
    @media only screen and (min-width: 576px) and (max-width: 1024px) {
      width: 50%;
      height: auto;
    }
  }
}

@media only screen and (min-width: 1024px) {
  #usecase {
    width: 90%;
    padding-top: 2rem;
    margin: 0 auto;
    .home-sec-4-box {
      display: flex;
      flex-flow: row nowrap;

      #tabs {
        justify-content: flex-start;
        margin-top: 2.5rem;
      }
      .tab-text {
        width: 87%;
      }
      ul .tab {
        padding: 0 1.5rem;
      }
      #tab-wrapper,
      .tab-images {
        // width: 100%;
      }
      .tab-images {
        display: flex;
        flex-flow: row nowrap;

        .active {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-evenly;
        }
        img {
          width: 11rem;
          height: 50vh;
        }
      }
      #sec-4-title {
        text-align: left;
        h2 {
          font-size: 2.75rem;
        }
      }
    }
  }
}
</style>

<script>
/* Vue Section */
// import TekkisButton from "./buttons/TekkisButton.vue";

export default {
  // components: {
  //   // TekkisButton,
  // },
  // computed: {
  //   lineStyles() {
  //     return this.lineTemp == null ? this.line : this.lineTemp;
  //   },
  //   activeTabColor() {
  //     if (this.hoverTab !== null)
  //       return (tab) => (tab == this.hoverTab ? "text-color-blue" : "");

  //     return (tab) => (tab == this.currentTab ? "text-color-blue" : "");
  //   },
  // },
  // mounted() {
  //   this.updateLine(0);
  // },
  data() {
    return {
      isActive: true,
      tPaySelected: true,
      tVerifySelected: false,
      // hamSelected: true,
      // currentTab: 0,
      // timer: "",
      // hoverTab: null,
      // line: {
      //   left: 0,
      //   width: 69,
      // },
      // lineTemp: null,
      tabContents: [
        {
          tab: "Identity Verification",
          title: "SEE THE REAL IDENTITY.",
          description:
            "Create trusted user identifies with <span style='color:#FF3377'>t-VerifyID</span>. Scan user ID<br>photo from any device, before checking it's genuine.<br>Combine with <span style='color:#FF3377'>t-VerifyFace</span>. Add liveness detection to do a<br>face match and protect against impersonation.",
          images: ["selfie.png", "verify-document.png"],
          btntitle: "Explore t-Verify",
          scrollto: "'#tverifyid'",
        },
        {
          tab: "Online Payments",
          title: "ACCEPT PAYMENTS ONLINE.",
          description:
            "A simple way to accept payments online,<br>in-app and on your website.",
          images: ["checkout.png", "payment_successful.png"],
          btntitle: "Loop me in",
          scrollto: "'#tverifyface'",
        },
      ],
    };
  },
  methods: {
    tPayOnClick() {
      if (!this.tPaySelected) {
        this.tPaySelected = true;
        this.tVerifySelected = false;
        console.log(this.tPaySelected);
        console.log(this.tVerifySelected);
      }
      return;
    },
    tVerifyOnClick() {
      if (!this.tVerifySelected) {
        this.tPaySelected = false;
        this.tVerifySelected = true;
        console.log(this.tPaySelected);
        console.log(this.tVerifySelected);
      }
      return;
    },
    hamOnClick() {
      if (!this.hamSelected) {
        this.hamSelected = true;
      } else {
        this.hamSelected = false;
      }
    },
  },
};
</script>
